import Vue from 'vue'
import Router from 'vue-router'
import menuModule from '@/store/modules/menu';

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/Charts')
const Widgets = () => import('@/views/Widgets')


// Web
const WebDashboard = () => import('@/views/web/Dashboard')
const WebVideo = () => import('@/views/web/video')
const WebAbout = () => import('@/views/web/about')
const WebAnnouncement = () => import('@/views/web/announcement')
const WebContact = () => import('@/views/web/contact')
const WebInstitution = () => import('@/views/web/institution')
const WebTeams = () => import('@/views/web/teams')
const WebReferences = () => import('@/views/web/references')
const WebFrequentlyAskedQuestions = () => import('@/views/web/frequently_asked_questions')
const WebBlog = () => import('@/views/web/blog')
const WebModulesPatientfollow = () => import('@/views/web/modules/patient_follow')
const WebModulesAiRobots = () => import('@/views/web/modules/airobots')
const WebModulesOncology = () => import('@/views/web/modules/oncology')
const WebModulesPolestar = () => import('@/views/web/modules/polestar')
const WebModulesStatistics = () => import('@/views/web/modules/statistics')
const WebModulesDecisionMakingSystem = () => import('@/views/web/modules/decision_making_system')
const WebGetOffer = () => import('@/views/web/getoffer')
const WebAgreement = () => import('@/views/web/agreement')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const Flags = () => import('@/views/icons/Flags')
const FontAwesome = () => import('@/views/icons/FontAwesome')
const SimpleLineIcons = () => import('@/views/icons/SimpleLineIcons')
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/Login')
const Logout = () => import('@/views/Logout')
const Register = () => import('@/views/Register')
const ResetPassword = () => import('@/views/ResetPassword')
const AdministratorRegister = () => import('@/views/Register')

// Patient
const Log = () => import('@/views/log')
const Help = () => import('@/views/help')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [{
      path: '/',
      redirect: 'dashboard',
      name: 'Home',
      component: DefaultContainer,
      children: [{
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'web/dashboard',
          name: 'WebDashboard',
          component: WebDashboard,
          meta: {
            'web': 1
          }
        },
        {
          path: 'theme',
          redirect: '/theme/colors',
          name: 'Theme',
          component: {
            render(c) { return c('router-view') }
          },
          children: [{
              path: 'colors',
              name: 'Colors',
              component: Colors
            },
            {
              path: 'typography',
              name: 'Typography',
              component: Typography
            }
          ]
        },
        {
          path: 'charts',
          name: 'Charts',
          component: Charts
        },
        {
          path: 'widgets',
          name: 'Widgets',
          component: Widgets
        },
        {
          path: '/help',
          name: 'help',
          component: Help
        },
        {
          path: '/log',
          name: 'log',
          component: Log
        },
        ...generateRoutesFromWeb(),
        {
          path: 'base',
          redirect: '/base/cards',
          name: 'Base',
          component: {
            render(c) { return c('router-view') }
          },
          children: [{
              path: 'cards',
              name: 'Cards',
              component: Cards
            },
            {
              path: 'forms',
              name: 'Forms',
              component: Forms
            },
            {
              path: 'switches',
              name: 'Switches',
              component: Switches
            },
            {
              path: 'tables',
              name: 'Tables',
              component: Tables
            },
            {
              path: 'tabs',
              name: 'Tabs',
              component: Tabs
            },
            {
              path: 'breadcrumbs',
              name: 'Breadcrumbs',
              component: Breadcrumbs
            },
            {
              path: 'carousels',
              name: 'Carousels',
              component: Carousels
            },
            {
              path: 'collapses',
              name: 'Collapses',
              component: Collapses
            },
            {
              path: 'jumbotrons',
              name: 'Jumbotrons',
              component: Jumbotrons
            },
            {
              path: 'list-groups',
              name: 'List Groups',
              component: ListGroups
            },
            {
              path: 'navs',
              name: 'Navs',
              component: Navs
            },
            {
              path: 'navbars',
              name: 'Navbars',
              component: Navbars
            },
            {
              path: 'paginations',
              name: 'Paginations',
              component: Paginations
            },
            {
              path: 'popovers',
              name: 'Popovers',
              component: Popovers
            },
            {
              path: 'progress-bars',
              name: 'Progress Bars',
              component: ProgressBars
            },
            {
              path: 'tooltips',
              name: 'Tooltips',
              component: Tooltips
            }
          ]
        },
        {
          path: 'buttons',
          redirect: '/buttons/standard-buttons',
          name: 'Buttons',
          component: {
            render(c) { return c('router-view') }
          },
          children: [{
              path: 'standard-buttons',
              name: 'Standard Buttons',
              component: StandardButtons
            },
            {
              path: 'button-groups',
              name: 'Button Groups',
              component: ButtonGroups
            },
            {
              path: 'dropdowns',
              name: 'Dropdowns',
              component: Dropdowns
            },
            {
              path: 'brand-buttons',
              name: 'Brand Buttons',
              component: BrandButtons
            }
          ]
        },
        {
          path: 'icons',
          redirect: '/icons/font-awesome',
          name: 'Icons',
          component: {
            render(c) { return c('router-view') }
          },
          children: [{
              path: 'coreui-icons',
              name: 'CoreUI Icons',
              component: CoreUIIcons
            },
            {
              path: 'flags',
              name: 'Flags',
              component: Flags
            },
            {
              path: 'font-awesome',
              name: 'Font Awesome',
              component: FontAwesome
            },
            {
              path: 'simple-line-icons',
              name: 'Simple Line Icons',
              component: SimpleLineIcons
            }
          ]
        },
        {
          path: 'notifications',
          redirect: '/notifications/alerts',
          name: 'Notifications',
          component: {
            render(c) { return c('router-view') }
          },
          children: [{
              path: 'alerts',
              name: 'Alerts',
              component: Alerts
            },
            {
              path: 'badges',
              name: 'Badges',
              component: Badges
            },
            {
              path: 'modals',
              name: 'Modals',
              component: Modals
            }
          ]
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [{
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      path: '/resetpassword',
      name: 'resetpassword',
      component: ResetPassword
    }
  ]
})

function generateRoutesFromWeb() {
  let web_routes = [{
      path: 'web/about',
      name: 'Hakkında',
      component: WebAbout,
      meta: {
        'web': 1
      }
    },
    {
      path: 'web/video',
      name: 'Videolar',
      component: WebVideo,
      meta: {
        'web': 1
      }
    },
    {
      path: 'web/announcement',
      name: 'Duyurular',
      component: WebAnnouncement,
      meta: {
        'web': 1
      }
    },
    {
      path: 'web/contact',
      name: 'İletişim',
      component: WebContact,
      meta: {
        'web': 1
      }
    },
    {
      path: 'web/institution',
      name: 'Kurumsal',
      component: WebInstitution,
      meta: {
        'web': 1
      }
    },
    {
      path: 'web/teams',
      name: 'Takımlar',
      component: WebTeams,
      meta: {
        'web': 1
      }
    },
    {
      path: 'web/references',
      name: 'Referanslarımız',
      component: WebReferences,
      meta: {
        'web': 1
      }
    },
    {
      path: 'web/frequently-asked-questions',
      name: 'Sıkça Sorulan Sorular',
      component: WebFrequentlyAskedQuestions,
      meta: {
        'web': 1
      }
    },
    {
      path: 'web/blog',
      name: 'Blog',
      component: WebBlog,
      meta: {
        'web': 1
      }
    },
    {
      path: 'web/getoffer',
      name: 'Teklif Al',
      component: WebGetOffer,
      meta: {
        'web': 1
      }
    },
    {
      path: 'web/agreement',
      name: 'Sözleşme',
      component: WebAgreement,
      meta: {
        'web': 1
      }
    },
    /*    {
          path: 'web/products',
          name: 'Ürünler',
          component: WebProducts,
          meta: {
            'web': 1
          }
        },
    */
    {
      path: 'web/modules/patient_follow',
      name: 'Hasta Takip',
      component: WebModulesPatientfollow,
      meta: {
        'web': 1
      }
    },
    {
      path: 'web/modules/oncology',
      name: 'Onkoloji',
      component: WebModulesOncology,
      meta: {
        'web': 1
      }
    },
    {
      path: 'web/modules/statistics',
      name: 'İstatistik',
      component: WebModulesStatistics,
      meta: {
        'web': 1
      }
    },
    {
      path: 'web/modules/airobots',
      name: 'Yapay Zeka Robotları',
      component: WebModulesAiRobots,
      meta: {
        'web': 1
      }
    },
    {
      path: 'web/modules/decision_making_system',
      name: 'Karar Destek Sistemi',
      component: WebModulesDecisionMakingSystem,
      meta: {
        'web': 1
      }
    },
    {
      path: 'web/modules/polestar',
      name: 'Kutup Yıldızı',
      component: WebModulesPolestar,
      meta: {
        'web': 1
      }
    }
  ];
  return web_routes;
}
