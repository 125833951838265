import store from './store';
import menuModule from '@/store/modules/menu';
var core_uı_routes = [{
    title: true,
    name: 'Theme',
    class: '',
    wrapper: {
      element: '',
      attributes: {}
    }
  },
  {
    name: 'Colors',
    url: '/theme/colors',
    icon: 'icon-drop'
  },
  {
    name: 'Typography',
    url: '/theme/typography',
    icon: 'icon-pencil'
  },
  {
    title: true,
    name: 'Components',
    class: '',
    wrapper: {
      element: '',
      attributes: {}
    }
  },
  {
    name: 'Base',
    url: '/base',
    icon: 'icon-puzzle',
    children: [{
        name: 'Breadcrumbs',
        url: '/base/breadcrumbs',
        icon: 'icon-puzzle'
      },
      {
        name: 'Cards',
        url: '/base/cards',
        icon: 'icon-puzzle'
      },
      {
        name: 'Carousels',
        url: '/base/carousels',
        icon: 'icon-puzzle'
      },
      {
        name: 'Collapses',
        url: '/base/collapses',
        icon: 'icon-puzzle'
      },
      {
        name: 'Forms',
        url: '/base/forms',
        icon: 'icon-puzzle'
      },
      {
        name: 'Jumbotrons',
        url: '/base/jumbotrons',
        icon: 'icon-puzzle'
      },
      {
        name: 'List Groups',
        url: '/base/list-groups',
        icon: 'icon-puzzle'
      },
      {
        name: 'Navs',
        url: '/base/navs',
        icon: 'icon-puzzle'
      },
      {
        name: 'Navbars',
        url: '/base/navbars',
        icon: 'icon-puzzle'
      },
      {
        name: 'Paginations',
        url: '/base/paginations',
        icon: 'icon-puzzle'
      },
      {
        name: 'Popovers',
        url: '/base/popovers',
        icon: 'icon-puzzle'
      },
      {
        name: 'Progress Bars',
        url: '/base/progress-bars',
        icon: 'icon-puzzle'
      },
      {
        name: 'Switches',
        url: '/base/switches',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tables',
        url: '/base/tables',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tabs',
        url: '/base/tabs',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tooltips',
        url: '/base/tooltips',
        icon: 'icon-puzzle'
      }
    ]
  },
  {
    name: 'Buttons',
    url: '/buttons',
    icon: 'icon-cursor',
    children: [{
        name: 'Buttons',
        url: '/buttons/standard-buttons',
        icon: 'icon-cursor'
      },
      {
        name: 'Button Dropdowns',
        url: '/buttons/dropdowns',
        icon: 'icon-cursor'
      },
      {
        name: 'Button Groups',
        url: '/buttons/button-groups',
        icon: 'icon-cursor'
      },
      {
        name: 'Brand Buttons',
        url: '/buttons/brand-buttons',
        icon: 'icon-cursor'
      }
    ]
  },
  {
    name: 'Charts',
    url: '/charts',
    icon: 'icon-pie-chart'
  },
  {
    name: 'Icons',
    url: '/icons',
    icon: 'icon-star',
    children: [{
        name: 'CoreUI Icons',
        url: '/icons/coreui-icons',
        icon: 'icon-star',
        badge: {
          variant: 'info',
          text: 'NEW'
        }
      },
      {
        name: 'Flags',
        url: '/icons/flags',
        icon: 'icon-star'
      },
      {
        name: 'Font Awesome',
        url: '/icons/font-awesome',
        icon: 'icon-star',
        badge: {
          variant: 'secondary',
          text: '4.7'
        }
      },
      {
        name: 'Simple Line Icons',
        url: '/icons/simple-line-icons',
        icon: 'icon-star'
      }
    ]
  },
  {
    name: 'Notifications',
    url: '/notifications',
    icon: 'icon-bell',
    children: [{
        name: 'Alerts',
        url: '/notifications/alerts',
        icon: 'icon-bell'
      },
      {
        name: 'Badges',
        url: '/notifications/badges',
        icon: 'icon-bell'
      },
      {
        name: 'Modals',
        url: '/notifications/modals',
        icon: 'icon-bell'
      }
    ]
  },
  {
    name: 'Widgets',
    url: '/widgets',
    icon: 'icon-calculator',
    badge: {
      variant: 'primary',
      text: 'NEW'
    }
  },
  {
    divider: true
  },
  {
    title: true,
    name: 'Extras'
  },
  {
    name: 'Pages',
    url: '/pages',
    icon: 'icon-star',
    children: [{
        name: 'Register',
        url: '/pages/register',
        icon: 'icon-star'
      },
      {
        name: 'Error 404',
        url: '/pages/404',
        icon: 'icon-star'
      },
      {
        name: 'Error 500',
        url: '/pages/500',
        icon: 'icon-star'
      }
    ]
  },
  {
    name: 'Disabled',
    url: '/dashboard',
    icon: 'icon-ban',
    badge: {
      variant: 'secondary',
      text: 'NEW'
    },
    attributes: { disabled: true },
  },
  {
    name: 'Download CoreUI',
    url: 'http://coreui.io/vue/',
    icon: 'icon-cloud-download',
    class: 'mt-auto',
    variant: 'success',
    attributes: { target: '_blank', rel: 'noopener' }
  },
  {
    name: 'Try CoreUI PRO',
    url: 'http://coreui.io/pro/vue/',
    icon: 'icon-layers',
    variant: 'danger',
    attributes: { target: '_blank', rel: 'noopener' }
  }
];

export default {
  items: [],
  f_updateItems: function() {
    this.items = [
      ...generateRoutesFromMenu(),
      ...generateRoutesFromWeb(),
      ...coreUıRoutesFunc()
    ];
  }
}
// wisdom user can see coreuı features
function coreUıRoutesFunc() {
  let routes = [];
  let user = JSON.parse(localStorage.getItem('user'));
  if (user && user.permissions_result.indexOf('wisdom') !== -1) {
    return core_uı_routes;
  } else {
    return [];
  }
}
// Menu should have 2 levels.
function generateRoutesFromMenu() {
  let user = JSON.parse(localStorage.getItem('user'));
  let menu = JSON.parse(JSON.stringify(menuModule.state.items));
  // console.log('generateRoutesFromMenu user', user);
  // console.log('generateRoutesFromMenu menu', menu);
  let routes = [];
  // console.log(store);
  if (store.getters.patient_selected && store.getters.patient_selected.selected) {
    routes = [{
        name: 'Anasayfa',
        url: '/dashboard',
        wdm16_id: '6258',
        icon: 'icon-bubbles',
        badge: { variant: 'primary', text: '...' }
      },
      {
        title: true,
        name: 'Hasta',
        class: '',
        icon: 'icon-calculator',
        badge: { variant: 'success', text: '*' },
        wrapper: {
          element: '',
          attributes: {}
        }
      }
    ];
    for (let i = 0, l = menu.length; i < l; i++) {
      let item = {};
      let tmp_children = [];
      for (let item_perm_id in menu[i].permissions) {
        if (user && user.permissions_result.indexOf(menu[i].permissions[item_perm_id]) !== -1) {
          item = JSON.parse(JSON.stringify(menu[i]));
          if (item.path) {
            item.url = item.path;
          }
          if (item.caption) {
            item.name = item.caption;
          }
          break;
        }
      }
      if (!menu[i].component) {
        if (menu[i].children) {
          for (let k in menu[i].children) {
            for (let child_perm_id in menu[i].children[k].sub_permissions) {
              if (user && user.permissions_result.indexOf(menu[i].children[k].sub_permissions[child_perm_id]) !== -1) {
                item.children[k] = JSON.parse(JSON.stringify(menu[i].children[k]));
                if (menu[i].children[k].path) {
                  item.children[k].url = menu[i].children[k].path;
                }
                if (menu[i].children[k].caption) {
                  item.children[k].name = menu[i].children[k].caption;
                }
                tmp_children.push(item.children[k]);
                break;
              }
            }
            // delete item.children[k];
          }
        }
      }
      if (tmp_children.length > 0) {
        item.children = tmp_children;
      }
      if (Object.keys(item).length !== 0 && (item.url !== '' || item.name !== '' || item.children)) {
        routes.push(item);
      }
    }
  }
  return routes;
}

function generateRoutesFromWeb() {
  let web_routes = [{
      title: true,
      name: 'Hakkında',
      class: '',
      wrapper: {
        element: '',
        attributes: {}
      },
      wdm16_id: '6717'
    },
    {
      name: 'Anasayfa',
      url: '/web/dashboard',
      icon: 'icon-speech',
      wdm16_id: '4112'
    },
    {
      name: 'Hakkımızda',
      url: '/web/about',
      icon: 'icon-speech',
      wdm16_id: '10991'
    },
    {
      name: 'Videolar',
      url: '/web/video',
      icon: 'icon-speech',
      wdm16_id: '10992'
    },
    /*
    {
      name: 'Blog',
      url: '/web/blog',
      icon: 'icon-pencil'
    },
    */
    /*
    {
      name: 'Duyurular',
      url: '/web/announcement',
      icon: 'icon-pie-chart'
    },
    */
    {
      name: 'İletişim',
      url: '/web/contact',
      icon: 'icon-pie-chart',
      wdm16_id: '7124'
    },
    /*    {
          name: 'Ürünler',
          url: '/web/products',
          icon: 'icon-pie-chart'
        },
    */
    {
      name: 'Kurumsal',
      url: '/web/institution',
      icon: 'icon-paper-clip',
      wdm16_id: '10993'
    },
    /*  {
        name: 'Takımlar',
        url: '/web/teams',
        icon: 'icon-paper-clip'
      },
    */
    {
      name: 'Referanslar',
      url: '/web/references',
      icon: 'icon-paper-clip',
      wdm16_id: '3930'
    },
    {
      name: 'Sıkça Sorulan Sorular',
      url: '/web/frequently-asked-questions',
      icon: 'icon-paper-clip',
      wdm16_id: '10994'
    },
    /*    {
          name: 'Teklif Al',
          url: '/web/getoffer',
          icon: 'icon-bubble',
          user: 1
        },
    */
    {
      title: true,
      name: 'Modüller',
      class: '',
      wrapper: {
        element: '',
        attributes: {}
      },
      wdm16_id: '6665'
    },
    {
      name: 'Hasta Takip',
      url: '/web/modules/patient_follow',
      icon: 'icon-share',
      wdm16_id: '6253'
    },
    {
      name: 'Onkoloji',
      url: '/web/modules/oncology',
      icon: 'icon-power',
      wdm16_id: '4600'
    },
    {
      name: 'Karar Destek Sistemi',
      url: '/web/modules/decision_making_system',
      icon: 'icon-support',
      wdm16_id: '6307'
    },
    {
      name: 'Kutup Yıldızı',
      url: '/web/modules/polestar',
      icon: 'icon-star',
      wdm16_id: '6305'
    },
    {
      name: 'İstatistik',
      url: '/web/modules/statistics',
      icon: 'icon-graph',
      wdm16_id: '3426'
    },
    {
      name: 'Yapay Zeka Robotları',
      url: '/web/modules/airobots',
      icon: 'icon-diamond',
      wdm16_id: '10995'
    },
    {
      title: true,
      name: 'Yasal',
      class: '',
      wrapper: {
        element: '',
        attributes: {}
      },
      wdm16_id: '10996'
    },
    {
      name: 'Sözleşme & Metin',
      url: '/web/agreement',
      icon: 'icon-pencil',
      wdm16_id: '10997'
    }
  ];
  return web_routes;
}
